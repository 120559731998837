import React, {useState, useEffect} from 'react';
import type Disparador from './models/season';
import type HeaderObject from './models/header';
import {
	getSeasons, getLandingData,
} from './repositories/landingRepository';
import type AboutUsObject from './models/aboutUs';
import 'animate.css/animate.min.css';
import type Sponsor from './models/sponsor';
import type ContactInfo from './models/contactInfo';
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import MainPage, {findLastSeason} from './components/mainPage/mainPage';
import Disparadores from './components/disparadores/disparadores';
import DisparadorContent from './components/disparadorContent/disparadorContent';
import TextContent from './components/textContent/textContent';
import VideoContent from './components/videoContent/videoContent';
import NotFoundPage from './components/notFoundPage/notFoundPage';
import UploadSongPage from './components/uploadSongPage/uploadSongPage';

function App() {
	return (
		<Router>
			<AppWithAutoRouter/>
		</Router>
	);
}

function AppWithAutoRouter() {
	const [seasons, setSeasons] = useState<Disparador[] | undefined>();
	const [contactInfo, setContactInfo] = useState<ContactInfo | undefined>();
	const [sponsors, setSponsors] = useState<Sponsor[] | undefined>();
	const [header, setHeader] = useState<HeaderObject | undefined>();
	const [aboutUs, setAboutUs] = useState<AboutUsObject | undefined>();
	const [timerData, setTimerData] = useState<string | undefined>();
	const [isLoading, setIsLoading] = useState(true);

	const handleGetSeasons = async () => {
		const landingData = getLandingData();
		const seasonsPromise = getSeasons();
		const landingResult = await landingData;
		setHeader(landingResult.header);
		setTimerData(landingResult.timer);
		setAboutUs(landingResult.about);
		setSponsors(landingResult.sponsors);
		setContactInfo(landingResult.contact);
		const seasonsPromiseResult = await seasonsPromise;
		setSeasons(seasonsPromiseResult);
		const isPathHome = window.location.pathname === '/';
		if (!isPathHome) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		void handleGetSeasons();
	}, []);

	return (
		<Routes>
			<Route path='/' element={<MainPage setIsLoading={setIsLoading} aboutUs={aboutUs} contactInfo={contactInfo} isLoading={isLoading} header={header} seasons={seasons} sponsors={sponsors} />} />
			<Route path='/disparadores' element={<Disparadores contactInfo={contactInfo} disparadoresElements={seasons} isLoading={isLoading} />} />
			<Route path='/disparador' element={<DisparadorContent isAppLoading={isLoading} disparadores={seasons} contactInfo={contactInfo} lastSeasonName={findLastSeason(seasons)?.title} timerData={timerData}/>} />
			<Route path='/contenido-texto' element={<TextContent isAppLoading={isLoading} contactInfo={contactInfo}/>} />
			<Route path='/contenido-video' element={<VideoContent isAppLoading={isLoading} contactInfo={contactInfo}/>} />
			<Route path='/subir-canción' element={<UploadSongPage contactInfo={contactInfo} disparadores={seasons} isAppLoading={isLoading} lastSeasonName={findLastSeason(seasons)?.title} timerData={timerData}/>} />
			<Route path='/*' element={<NotFoundPage contactInfo={contactInfo} isAppLoading={isLoading}/>} />
		</Routes>
	);
}

export default App;
